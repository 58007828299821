html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif
}

#cardExp, #cardEdu {
  min-height: 300px;
}